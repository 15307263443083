<template>
  <b-overlay :show="loading">
    <b-row>
      <b-col cols="12">
        <b-card-actions :no-actions="true" title="List Barang">
          <section class="d-flex justify-content-between">
            <section>
              <b-button v-if="allowCreate()" variant="primary" class="mb-2 mr-2" @click.prevent="$router.push({ name: 'route-barang-add' })"> Tambah </b-button>
              <b-button v-if="allowDelete() && showMassDelButton" variant="outline-danger" class="mb-2" @click.prevent="confirmMassDelete"> Hapus </b-button>
            </section>
            <section>
              <b-button variant="outline-primary" @click.prevent="exportBarang"> Export </b-button>
            </section>
          </section>
          <!-- search input -->

          <!--  -->
          <!-- <data-barang :datas="items" :total="totalRows" @load="(val) => updatePage(val)" /> -->

          <!-- Form Filter -->
          <header class="mb-1">
            <b-row>
              <!-- Page Limit -->
              <b-col md="2" sm="4" class="my-1">
                <b-form-group class="mb-0">
                  <label class="d-inline-block text-sm-left mr-50">Per page </label>
                  <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-50" />
                </b-form-group>
              </b-col>
              <!-- / -->
              <!-- Sort By -->
              <b-col md="4" sm="6" class="my-1">
                <b-form-group label="Sort" label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="sortBySelect" class="mb-0">
                  <b-input-group size="sm">
                    <b-form-select id="sortBySelect" v-model="sortBy" size="sm" :options="sortOptions" class="w-75">
                      <template v-slot:first>
                        <option value="">-- none --</option>
                      </template>
                    </b-form-select>
                    <b-form-select v-model="sortDesc" size="sm" :disabled="!sortBy" class="w-25">
                      <option :value="false">Asc</option>
                      <option :value="true">Desc</option>
                    </b-form-select>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <!-- / -->
              <!-- Filter Search -->
              <b-col md="6" sm="2" class="my-1">
                <b-form-group label="Filter" label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="filterInput" class="mb-0" size="sm">
                  <b-input-group>
                      <b-form-input v-model="filter" placeholder="Tekan enter untuk cari"
                        @keydown.enter.prevent="getData()"></b-form-input>
                      <!-- <b-form-input placeholder="Button on right" /> -->
                      <b-input-group-append>
                        <b-button variant="outline-primary" @click="getData()">
                          Cari
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                </b-form-group>
              </b-col>
              <!-- / -->
            </b-row>
          </header>
          <!-- /form filter -->
          <b-table
            striped
            small
            hover
            responsive
            :per-page="perPage"
            :items="items"
            :fields="fieldsOwner"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
          >
            <template #cell(harga_dasar)="{ item }"> Rp. {{ formatRupiah(item.harga_dasar) }} </template>
            <!-- Check box for show detail -->
            <template #cell(show_detail)="row">
              <b-form-checkbox v-model="row.detailsShowing" plain class="vs-checkbox-con" @change="row.toggleDetails">
                <span class="vs-checkbox">
                  <span class="vs-checkbox--check">
                    <i class="vs-icon feather icon-check" />
                  </span>
                </span>
                <span class="vs-label">{{ row.detailsShowing ? "Hide" : "Show" }}</span>
              </b-form-checkbox>
            </template>
            <!-- / -->
            <!-- Detail -->
            <template #row-details="{ toggleDetails, item }">
              <!-- <b-card> -->
              <b-row class="mb-1">
                <b-col cols="3">
                  <table border="3">
                    <tr>
                      <th>No</th>
                      <th>Varian</th>
                    </tr>
                    <tr v-for="(data, i) in item.varian_item" :key="i">
                      <td>{{ i + 1 }}</td>
                      <td>
                        {{ data.varian ? data.varian : "-" }}
                      </td>
                    </tr>
                  </table>
                </b-col>
                <b-col cols="8">
                  <table border="3">
                    <tr>
                      <!-- <th>No</th> -->
                      <th>Satuan Dari</th>
                      <th>Nilai</th>
                      <!-- <th>Satuan Barang</th> -->
                    </tr>
                    <tr v-for="(satuan, i) in item.konversi" :key="i">
                      <!-- <td>{{ i + 1 }}</td> -->
                      <!-- <td>{{ satuan.no }}</td> -->
                      <td>1 {{ satuan.satuan ? satuan.satuan.satuan : "-" }}</td>
                      <td>
                        {{ formatRupiah(satuan.nilai) }}
                      </td>
                      <!-- <td>
                    {{ formatRupiah(satuan.barang.satuan.satuan) }}
                  </td> -->
                    </tr>
                  </table>
                </b-col>
              </b-row>
              <b-button size="sm" variant="outline-danger" @click="toggleDetails" class="mb-1"> Hide </b-button>
              <!-- </b-card> -->
            </template>
            <template #cell(no)="{ index }">
              {{ index + 1 }}
            </template>
            <template #cell(jenis)="{ item }">
              {{ item.jenis == 1 ? "Rokok" : "Non-Rokok" }}
            </template>
            <template #cell(harga_jual)="{ item }">
              {{ item.harga_jual && item.harga_jual.length > 0 ? formatRupiah(item.harga_jual[0].harga) : 0 }}
            </template>

            <template #cell(id_satuan)="{ item }">
              {{ item.satuan ? item.satuan.satuan : "-" }}
            </template>
            <template #cell(action)="{ item }">
              <section class="d-flex justify-content-center">
                <b-button v-if="allowUpdate($route.meta.module)" :id="`edit-btn-${item.id}`" size="sm" @click.prevent="$router.push(`/barang/edit/${item.id}`)" class="mr-1" variant="outline-info">
                  <feather-icon icon="EditIcon" />
                </b-button>
                <b-tooltip :target="`edit-btn-${item.id}`">Edit</b-tooltip>

                <b-button v-if="allowDelete($route.meta.module)" :id="`delete-btn-${item.id}`" size="sm" @click.prevent="deleteSingle(item.id)" class="mr-1" variant="outline-danger">
                  <feather-icon icon="TrashIcon" />
                </b-button>
                <b-tooltip :target="`delete-btn-${item.id}`">Hapus</b-tooltip>
              </section>
            </template>
          </b-table>
          <b-row>
            <b-col cols="12">
              <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="center" size="sm" class="my-0" />
            </b-col>
          </b-row>
        </b-card-actions>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import service from "@/services";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import { BOverlay, BRow, BCol, BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BButton, BCardText, BTable, BInputGroupAppend, BInputGroup, BTooltip, BFormCheckbox } from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import DataBarang from "./components/DataBarang.vue";

export default {
  components: {
    DataBarang,
    BCardText,
    BOverlay,
    BButton,
    BRow,
    BCol,
    BCardActions,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BTable,
    BInputGroupAppend,
    BInputGroup,
    BTooltip,
    BFormCheckbox,
  },
  data() {
    return {
      pageOptions: [10, 20, 50],
      sortBy: null,
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      perPage: 10,
      totalRows: 1,
      currentPage: 1,
      loaded: true,
      fields: [
        { key: "show_detail", label: "#" },
        { key: "no", label: "No", sortable: true },
        { key: "nama", label: "Nama Barang", sortable: true },
        { key: "kode", label: "Kode" },
        { key: "varian", label: "Varian" },
        // { key: "harga_jual", label: "Harga Jual", sortable: true },
        { key: "id_satuan", label: "Satuan" },
        { key: "action", label: "#" },
      ],
      fieldsOwner: [
        { key: "show_detail", label: "#" },
        { key: "no", label: "No", sortable: true },
        { key: "nama", label: "Nama Barang", sortable: true },
        { key: "kode", label: "Kode" },
        { key: "varian", label: "Varian" },
        { key: "harga_dasar", label: "Harga Dasar", sortable: true },
        { key: "id_satuan", label: "Satuan" },
        { key: "action", label: "#" },
      ],
      loading: false,
      params: {
        order: "desc",
        // start: 0,
        // length: 10,
      },
      items: [],
      showMassDelButton: false,
      status: [
        {
          1: "Current",
          2: "Professional",
          3: "Rejected",
          4: "Resigned",
          5: "Applied",
        },
        {
          1: "light-primary",
          2: "light-success",
          3: "light-danger",
          4: "light-warning",
          5: "light-info",
        },
      ],
    };
  },
  watch: {
    perPage(value) {
      if (value) {
        this.getData();
      }
    },
    filter(keyword) {
      if (keyword.length > 2 || keyword.length == 0) {
        this.getData();
      }
    },
    currentPage(val) {
      if (val) {
        this.getData();
        // this.items
      }
    },
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    deleteSingle(id) {
      this.$bvModal
        .msgBoxConfirm("Anda yakin akan menghapus barang ini? Data yang didalamnya akan hilang!", {
          title: "Konfirmasi Penghapusan",
          size: "sm",
          okVariant: "danger",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          // this.boxTwo = value
          this.$store
            .dispatch("barang/save", [
              {
                id,
                fungsi: 1,
              },
            ])
            .then(() => this.getData())
            .catch((e) => this.displayError(e));
        });
    },
    async clearExportedFile(filename) {
      try {
        const config = {
          url: `/clear-export`,
          method: "get",
          params: {
            filename,
          },
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };
        const response = await service.sendRequest(config);

        return Promise.resolve(response.data);
      } catch (e) {
        if (e.response && e.response.data) {
          const blob = e.response.data;
          const text = await blob.text();
          const errorJson = JSON.parse(text);

          return Promise.reject(errorJson);
        }
        return Promise.reject(e);
      }
    },
    async print() {
      try {
        const config = {
          url: `/excel-data-barang`,
          method: "get",
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };
        const response = await service.sendRequest(config);

        return Promise.resolve(response.data);
      } catch (e) {
        return this.customRejectPromise(e);
      }
    },
    async exportBarang() {
      try {
        this.loading = true;

        const response = await this.print();
        await window.open(response);
        setTimeout(async () => {
          // delete selected file
          const arrFile = response.split("/");
          const filename = arrFile[arrFile.length - 1];

          await this.clearExportedFile(filename);
        }, 1000);

        this.loading = false;
      } catch (e) {
        this.loading = false;
        this.displayError(e);
        return false;
      }
    },
    updatePage(val) {
      this.currentPage = val;
      this.getData();
    },
    getData() {
      // if (this.items > 1)
      this.loading = true;
      this.loaded = false;
      const perPage = parseInt(this.perPage);
      const currentPage = (parseInt(this.currentPage) - 1) * perPage;
      const payload = {
        search: this.filter != null ? this.filter : null,
        order: "desc",
        start: currentPage,
        length: this.perPage,
        filter_by: "nama_lengkap",
      };
      this.$store
        .dispatch("barang/getDataV2", payload)
        .then((response) => {
          // this.items = null
          // console.log('HEMMM',this.items)

          let items = JSON.parse(JSON.stringify(this.$store.state.barang.datas));
          let items_total = this.$store.state.barang.totals;
          this.items = items;
          // this.items = items;
          this.totalRows = items_total;
          // this.$refs.table.refresh()
          // console.log('HEMMM',this.items)
          // this.$root.$emit("bv::refresh::table", "table");
          this.loaded = true;
          this.loading = false;
        })
        .catch((e) => {
          this.loaded = true;
          this.loading = false;
          console.error(e);
          this.displayError(e);

          return false;
        });
    },
    onItemChecked(payload) {
      if (payload.selectedRows.length > 0) {
        this.showMassDelButton = true;
      } else {
        this.showMassDelButton = false;
      }
    },
  },
  computed: {
    rows() {
      return this.items.total;
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields.filter((f) => f.sortable).map((f) => ({ text: f.label, value: f.key }));
    },
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: "light-primary",
        Professional: "light-success",
        Rejected: "light-danger",
        Resigned: "light-warning",
        Applied: "light-info",
        /* eslint-enable key-spacing */
      };

      return (status) => statusColor[status];
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  mounted() {
    this.getData();
  },
  created() {
    // this.getDataV2();
    // this.rows = barang.list;
  },
};
</script>
